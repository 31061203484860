import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import {
  Button,
  Title,
  Icon,
  RowCustom,
  ColCustom,
  LinkAnchor,
  List,
  ListItem,
} from 'components';

import FooterLogoSVG from 'assets/logo/FooterLogo.svg';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import { FacebookSVG, YoutubeSVG, InstagramSVG } from 'lib/icons';

import {
  FooterBg,
  FooterContainer,
  FooterContainerMobile,
  Socials,
} from './Footer.styles';
import { useMainUrl } from 'hooks/useMainUrl';

const Footer = () => {
  const intl = useIntl();
  const history = useHistory();
  const [mainUrl] = useMainUrl();
  React.useEffect(() => {
    console.log(
      'history.location.pathname.slice(1): ',
      history.location.pathname.slice(1, 3)
      // .slice(1, history.location.pathname.length)
    );
  }, []);
  return (
    <>
      <FooterBg>
        <FooterContainer>
          <RowCustom maxWidth="90%" padding="70px 4.3% 60px 5vw">
            {/* <Col> */}
            <RowCustom direction="row" width="100%" justify="space-between">
              <Col>
                <Row>
                  <Link to={mainUrl}>
                    <Icon
                      width="152"
                      height="44"
                      src={FooterLogoSVG}
                      alt="logo"
                      pointer
                    />
                  </Link>
                </Row>
                {/* <AnchorLink class="nav-link" href="#home">
                    <Button
                      size="16px"
                      size="16px"
                      primary
                      margin="35px 0"
                      width="195px"
                      padding="0"
                      height="40px"
                    >
                      {intl.formatMessage({
                        id: 'btn.footer.order',
                      })}
                    </Button>
                  </AnchorLink> */}
              </Col>
              <Col>
                <List>
                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/info/about`}>
                      {intl.formatMessage({
                        id: 'footer.about-company',
                      })}
                    </Link>
                  </ListItem>
                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/info/faq`}>
                      {intl.formatMessage({
                        id: 'footer.faq',
                      })}
                    </Link>
                  </ListItem>

                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/info/contacts`}>
                      {intl.formatMessage({
                        id: 'footer.contacts',
                      })}
                    </Link>
                  </ListItem>
                  {/* <ListItem margin="0 0 10px 0">
                    <Link
                      to={`/${history.location.pathname.slice(
                        1,
                        3
                      )}/info/faqterms`}
                    >
                      {intl.formatMessage({
                        id: 'footer.terms-conditions',
                      })}
                    </Link>
                  </ListItem> */}
                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/info/policy`}>
                      {intl.formatMessage({
                        id: 'footer.policy',
                      })}
                    </Link>
                  </ListItem>
                </List>
              </Col>
              <Col>
                <List>
                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/info/for-owner`}>
                      {intl.formatMessage({
                        id: 'footer.for-owners',
                      })}
                    </Link>
                  </ListItem>
                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/info/for-agency`}>
                      {intl.formatMessage({
                        id: 'footer.for-agency',
                      })}
                    </Link>
                  </ListItem>
                </List>
              </Col>
              <Col>
                <List>
                  <ListItem margin="0 0 10px 0">
                    <Link to={`/${localStorage.language}/destinations`}>
                      {intl.formatMessage({
                        id: 'footer.for-regions',
                      })}
                    </Link>
                  </ListItem>
                </List>
              </Col>
            </RowCustom>
            {/* </Col> */}
          </RowCustom>
          <RowCustom
            width="100%"
            position="absolute"
            padding="0 4.7% 10px 5%"
            bottom="0"
            justify="space-between"
            align="middle"
          >
            <Socials>
              <LinkAnchor
                href="https://www.facebook.com/Aktis.Villas"
                target="blank"
              >
                <FacebookSVG />
              </LinkAnchor>
              <LinkAnchor
                href="https://www.youtube.com/channel/UCG_DmJBfs4dooHlkbvswryA/?guided_help_flow=5 "
                target="blank"
              >
                <YoutubeSVG />
              </LinkAnchor>
              <LinkAnchor
                href="https://www.instagram.com/aktis.villas/"
                target="blank"
              >
                <InstagramSVG />
              </LinkAnchor>
            </Socials>
            <Title size="1.4rem" color="#fff">
              Copyright © 2021 Aktis Villas. All Rights Reserved.
            </Title>
          </RowCustom>
        </FooterContainer>
      </FooterBg>
      <FooterBg>
        <FooterContainerMobile paddingM="8px 5.7vw 0 5.7vw">
          <Col span={24} lg={24} md={24} sm={24} margin="0 0 30px 0">
            <Row>
              <Col span={24} lg={9} md={24} sm={24}>
                <Row justify="space-between" align="middle">
                  <Link to={mainUrl}>
                    <Icon
                      width="110"
                      height="30"
                      src={FooterLogoSVG}
                      alt="logo"
                      pointer
                    />
                  </Link>
                  <Socials>
                    <LinkAnchor
                      href="https://www.facebook.com/Aktis.Villas"
                      target="blank"
                    >
                      <FacebookSVG />
                    </LinkAnchor>
                    <LinkAnchor
                      href="https://www.youtube.com/channel/UCG_DmJBfs4dooHlkbvswryA/?guided_help_flow=5 "
                      target="blank"
                    >
                      <YoutubeSVG />
                    </LinkAnchor>

                    <LinkAnchor
                      href="https://www.instagram.com/aktis.villas/"
                      target="blank"
                    >
                      <InstagramSVG />
                    </LinkAnchor>
                  </Socials>
                </Row>
              </Col>
              <RowCustom margin="17px 0 0 0">
                <Col span={12} lg={5} md={12} sm={12}>
                  <List>
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/info/about`}>
                        {intl.formatMessage({
                          id: 'footer.about-company',
                        })}
                      </Link>
                    </ListItem>
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/info/faq`}>FAQ</Link>
                    </ListItem>
                    {/* <ListItem margin="0 0 10px 0">
                    <Link to="/">Отзывы</Link>
                  </ListItem> */}
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/info/contacts`}>
                        {intl.formatMessage({
                          id: 'footer.contacts',
                        })}
                      </Link>
                    </ListItem>
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/info/policy`}>
                        {intl.formatMessage({
                          id: 'footer.policy',
                        })}
                      </Link>
                    </ListItem>
                  </List>
                </Col>
                <ColCustom
                  span={12}
                  lg={5}
                  md={12}
                  sm={12}
                  padding="0 0 0 30px"
                >
                  <List>
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/info/for-agency`}>
                        {intl.formatMessage({
                          id: 'footer.for-agency',
                        })}
                      </Link>
                    </ListItem>
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/info/for-owner`}>
                        {intl.formatMessage({
                          id: 'footer.for-owners',
                        })}
                      </Link>
                    </ListItem>
                    {/* <ListItem margin="0 0 10px 0">
                    <Link to={`${ history.location.pathname.slice(1)}/info/faq`}>
                      {intl.formatMessage({
                        id: 'footer.for-developers',
                      })}
                    </Link>
                  </ListItem>
                  <ListItem margin="0 0 10px 0">
                    <Link to={`${ history.location.pathname.slice(1)}/info/faq`}>
                      {intl.formatMessage({
                        id: 'footer.for-airoports',
                      })}
                    </Link>
                  </ListItem> */}
                    <ListItem margin="0 0 10px 0">
                      <Link to={`/${localStorage.language}/destinations`}>
                        {intl.formatMessage({
                          id: 'footer.for-regions',
                        })}
                      </Link>
                    </ListItem>
                  </List>
                </ColCustom>
              </RowCustom>
            </Row>
          </Col>
          <Row justify="center">
            <Title size="12px" color="#fff" margin="48px 0 0 0 ">
              Copyright © 2024 Aktis Villas. All Rights Reserved.
            </Title>
          </Row>
        </FooterContainerMobile>
      </FooterBg>
    </>
  );
};

export { Footer };
